@keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes fadeInWithZoom {
  from {
      opacity: 0;
      transform: scale(.5);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}

@keyframes fadeOutWithZoom {
  from {
      opacity: 0;
      transform: scale(1.25);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}

@keyframes fadeInLeftWithZoom {
  from {
      opacity: 0;
      transform: scale(1.125) translate(4em);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}

@keyframes fadeInFromBelow {
    0% {
        opacity: 0;
        transform: translateY(.5em);
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
}

@keyframes fadeInFromLeft {
  0% {
      opacity: 0;
      transform: translateX(-1.25em);
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
}

@keyframes filterAttract {
  48%, 52% {
    transform: translateX(0);
    box-shadow: 0 0 1em rgba(0,0,0,0);
  }
  50% {
    transform: translateY(-3px) scale(1.02);
    box-shadow: 0 .2em .4em rgba(0,0,0,.1);
  }
}

