// widths
$small: 20rem; // 320px
$medium: 40rem; // 640px
$large: 64rem; // 1024px
$xlarge: 75rem; // 1200px
$max: 90rem; // 1440px

@mixin minwidth($query) {
    @media screen and (min-width: + $query) {
        @content;
    }
}
