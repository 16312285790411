@use "../import/colours";
@use "../import/mq";
@use "../import/fonts";
@use '~@magpie/ui-core/dist/styles/public-api' as mm;

body { // basic body defaults
    background: mm.$lightbggrey;
    color: colours.$warmgrey;
    font-family: fonts.$core;
    font-size: 1rem; // fallback
    font-size: clamp(.875rem, calc(.875rem + .33vw), 1rem);
    line-height: 1.666;
    margin: 0;
    padding: 0;
    min-width: 280px;
    opacity: 0;
    animation: fadeIn .2s .033s ease forwards;
    &::-webkit-scrollbar {
        width: 18px;
        background-color: #fff;
        box-shadow: inset 2px 0 3px rgba(0,0,0,.125);
    }

    &::-webkit-scrollbar-thumb {
        background-image: linear-gradient(to bottom, lighten(colours.$darkblue, 3%) 0, colours.$darkblue 100%);
        border: 5px solid rgba(0, 0, 0, 0); // add spacing around thumb
        background-clip: padding-box;
        border-radius: 1em;
    }
    &.no-scroll {
        position: fixed;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }
    &.rental {
        background: colours.$lightbggrey;
        color: #000;
    }
}

.container {
    margin: 0 auto;
    max-width: 80em;
    padding: 0 1em;
}

.noscript_msg {
    padding: 2em;
    text-align: center;
}

.loading-msg-basic {
    display: grid;
    place-items: center;
    height: 100vh;
    text-align: center;
    > div {
        padding: 2em 1em;
        opacity: 0;
        animation: fadeInFromBelow .5s .2s ease forwards;
        > h2 {
            font-size: calc(1.5em + .5vw);
        }
        > p {
            text-transform: uppercase;
            letter-spacing: .25em;
            opacity: .8;
            font-size: 1.5em;
            font-weight: 300;
            > span {
                opacity: 0;
                animation: fadeIn .45s ease alternate infinite;
                font-size: 1.5em;
                line-height: 1;
                &:nth-child(2) {
                    animation-delay: .15s;
                }
                &:nth-child(3) {
                    animation-delay: .3s;
                }
            }
        }
    }
    &--no-anim {
        > div {
            animation: fadeIn .1s .1s ease forwards;
        }
    }
}

.browser-error-msg {
    text-align: center;
    padding: 3em 1em;
    max-width: 60em;
    margin: 0 auto;
    &__logo {
        > img {
            width: 66%;
            max-width: 20em;
            max-height: auto;
        }
    }
    &__title {
        font-size: 1.5em;
        margin: .7em 0 1.25em;
        @include mq.minwidth(mq.$large) {
            font-size: 2em;
        }
    }
    &__link {
        > a {
            color: colours.$blue;
            font-weight: bold;
        }
    }
    &__agent {
        margin-top: 2em;
        padding-top: 2em;
        font-size: .75em;
        color: #999;
        border-top: 1px dashed #ddd;
        strong {
            letter-spacing: .1em;
        }
    }
}

.mmLink {
    @include mm.link;
}
