@use '~@magpie/ui-core/dist/styles/public-api' as mm;

$primary: #41acff;
$lightbggrey: #f4f4f4;
$lightblue: #d9f3fd;
$darkgrey: #2f2f3b;
$warmgrey: #414059;
$darkblue: #00537e;
$vdarkblue: #00101f;
$red: mm.$red;
$green: #009a01;
$darkgreen: #008a00;
$rentgreen: teal;
$blue: #41acff;
$yellow: #fec123;
$error: mm.$red;
