@use "./mq.scss";

*, :after, :before {
    box-sizing: border-box
}

//images
img, video {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
}

button, input, textarea {
    font-family: inherit;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

::placeholder {
    color: #747474;
}

//reset
.reset-list, .u-resetList {
    list-style: none;
    margin: 0;
    padding: 0;
}

.reset-margin {
    margin: 0;
}

.reset-lineHeight {
    line-height: 1;
}

//text alignment
.text-left, .u-textLeft {
    text-align: left
}

.text-center, .u-textCenter {
    text-align: center
}
@include mq.minwidth(37.5rem) {
    //600px (as per ux guidelines)
    .text-center-uxmed {
        text-align: center;
    }
}
@include mq.minwidth(52.5rem) {
    // 840px (as per ux guidelines)
    .text-center-uxlrg {
        text-align: center;
    }
}
.text-right, .u-textRight {
    text-align: right
}
